import { useEffect, useState } from "react";
import AuthUser from "./AuthUser";
import toast, { Toaster } from "react-hot-toast";
import moment from "moment";
import { Link, Navigate } from "react-router-dom";
import TaskSkeleton from "./TaskSkeleton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import { useNavigate } from 'react-router-dom';
import { useMemo } from 'react';


export default function StudentListAll() {
    const { http, user } = AuthUser();
    const [tasks, setTasks] = useState([]);
    const [TotalCount, setTotalCount] = useState();
    const [TotalCountMRP, setTotalCountMRP] = useState();
    const [date, setDate] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [departments, setdepartments] = useState([]);
    const [currentStatus, setCurrentStatus] = useState(null);
    const [loginUser, setloginUser] = useState(user.department_id);
    const [selectedStatus, setsselectedStatus] = useState();
    const [selectedStatusFinal, setsselectedStatusFinal] = useState("OPEN");
    const [selectedtp, setsselectedtp] = useState();
    const [selectedPurpose, setsselectePuropse] = useState();
    const [selectedAssigner, setsselecteAssiner] = useState();
    const [showModal, setShowModal] = useState(false);
    const [editUser, setEditUser] = useState({});
    const [cat, setCat] = useState([]);
    const [masterdata, setmasterdata] = useState([]);
    const [masterdataIndi, setMasterdataIndi] = useState([]);
    const [tasksind, setTasksInd] = useState([]);
    const [editColumn, setEditColumn] = useState(null);
    const [users, setUsers] = useState([]);
    const [stClass, setStClass] = useState([]);
    const [stDev, setstDev] = useState([]);
    const [stAgent, setstAgent] = useState([]);
    const [allStudent, setAllStudent] = useState([]);
    const [selStudent, setselStudent] = useState([]);
    const [selectedStud, setSelectedStudent] = useState("");
    const [selectedClassStud, setselectedClassStud] = useState("1");
    const [selectedDevStud, setselectedDevStud] = useState("");
    const [selectedDevAgent, setselectedDevAgent] = useState("");
    const [selectedDevStudType, setselectedDevStudType] = useState("");
    const [message, setMessage] = useState('');
    const [formData, setFormData] = useState({
        photo: '',
    })
    const [selectedNewOld, setSelectedNewOld] = useState("");

    useEffect(() => {
        http.get(`/class?company_id=${user.company_id}`).then((res) => {
            setStClass(res.data.data);
        });

        http.get(`/student_all?company_id=${user.company_id}`).then((res) => {
            setAllStudent(res.data.data);
        });
        http.get(`/devision`).then((res) => {
            setstDev(res.data.data);
        });

        http.get(`/agent?company_id=${user.company_id}`).then((res) => {
            setstAgent(res.data.data);
        });

        //if user selected directly without filter
        if (selectedStud) {
            setselectedClassStud("");
        }

        const valueToSet = selectedStud ? selectedStud.value : " ";
        fetchTaskDetails(valueToSet, selectedClassStud, selectedDevStud, selectedDevStudType, selectedNewOld, selectedDevAgent);

    }, [selectedStud,
        selectedClassStud,
        selectedDevStud,
        selectedDevStudType,
        selectedNewOld,
        selectedDevAgent
    ]);
    const fetchTaskDetails = (idStd, clasStd, devStd, tType, newOld, agent) => {
        setIsLoading(true);
        let endpoint = `/student_marks?id=${idStd}&dev=${devStd}&class=${clasStd}&type=${tType}&new_old=${newOld}&company_id=${user.company_id}&company_id=${user.company_id}&agent=${agent}`;

        http
            .get(endpoint)
            .then((res) => {
                setIsLoading(false);
                if (Array.isArray(res.data.data)) {
                    setTasks(res.data.data);
                    setTotalCount(res.data.total_count);
                    setTotalCountMRP(res.data.total_count_mrp);
                } else {
                    setIsLoading(false);
                    console.error("API response is not an array");
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.error("Error fetching tasks:", error);
            });
    };
    const statusUpdate = (e) => {
        setCurrentStatus(e.target.value);
    };

    const handleSave = async (taskId) => {
        if (currentStatus === null || taskId === null) {
            toast.error("Please select proper status");
            return true;
        }
        try {
            const response = await http.put(
                `task_status_update?task_id=${taskId}&status=${encodeURIComponent(
                    currentStatus
                )}`
            );
            toast.success("Task status updated successfully !");
            fetchTaskDetails();
        } catch (error) {
            console.error("Error fetching tasks:", error);
            toast.error("Please select proper item");
        }
    };

    const initialState = {
        revised_date: "",
        revised_remark: "",
    };

    const handleChangeModal = (e) => {
        setFormData1({
            ...formData1,
            [e.target.name]: e.target.value,
        });
        if (e.target.name === "category") {
            fetchUsersForDepartment(e.target.value);
        }
        if (e.target.name === "master_id") {
            fetchMasterIndividual(e.target.value);
        }
    };

    const fetchUsersForDepartment = async (departmentId) => {
        try {
            const response = await http.get(`/master_cat?cat=${departmentId}`);
            setmasterdata(response.data.users);
        } catch (error) {
            console.error("Error fetching users:", error);
        }
    };

    const deleteTask = async (taskId) => {
        await http
            .delete(`/student_del/${taskId}`)
            .then((response) => {
                toast.success("Student Data Deleted successfully !");
                window.location.reload();
            })
            .catch((err) => {
                console.error("Error deleting task", err);
            });
    };



    const [formData1, setFormData1] = useState({
        master_id: "",
        status: "",
        final_status: "",
    });

    const openEditModal = async (index, userData) => {
        setEditColumn(index);
        setShowModal(true);
        setselStudent(userData);
    };

    const closeModal = () => {
        setShowModal(false);
        setEditColumn("");
        setselStudent("");
    };



    const fetchMasterIndividual = async (msterId) => {
        try {
            const response = await http.get(`/master_cat_indi?cat=${msterId}`);
            setMasterdataIndi(response.data.users);
        } catch (error) {
            console.error("Error fetching users:", error);
        }
    };

    const DropdownOption = ({ user }) => {
        return (
            <option key={user.id} value={user.id}>
                {user.item_name}
            </option>
        );
    };

    const noOptionsMessage = ({ inputValue }) => {
        if (isLoading) {
            return 'Loading...';
        } else if (!inputValue) {
            return 'Loading...';
        } else {
            return 'No options';
        }
    };

    const options = useMemo(() => {
        if (isLoading) {
            return [{ value: '', label: 'Loading...' }];
        }
        return allStudent.map((user) => ({
            value: user.id,
            label: `${user.adm_no} - ${user.ext_2} ${user.ext_3}-${user.class}-${user.section}-${user.mobile}`,
        }));
    }, [allStudent, isLoading]);

    const handleSelectChange = (selectedOption) => {
        setSelectedStudent(selectedOption);
    };

    const handleSelectChangeClass = (e) => {
        const selectedValue = e.target.value;
        setselectedClassStud(selectedValue);
    };

    const handleSelectChangeDev = (e) => {
        const selectedValueDev = e.target.value;
        setselectedDevStud(selectedValueDev);
    };

    const handleSelectChangeAgent = (e) => {
        const selectedValueDev = e.target.value;
        setselectedDevAgent(selectedValueDev);
    };

    const handleClearFields = () => {
        window.location.reload();
    };


    const handleSelectChangeDevWay = (e) => {
        const selectedValueDevType = e.target.value;
        setselectedDevStudType(selectedValueDevType);
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const newFormData = new FormData();
            newFormData.append('photo', file);
            setFormData(newFormData);
            console.log('File appended to FormData:', file);
        } else {
            console.log('No file selected');
        }

    };

    const saveEditedCustemer = async (e) => {
        e.preventDefault();
        try {
            console.log('Submitting form data:', formData);
            await http.post(`/photoEdit/${editColumn}`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            toast.success("Profile image updated successfully!");
        } catch (error) {
            console.error('Error updating student:', error.response?.data || error.message);
            toast.error("Failed to update profile image.");
        }
        setEditColumn("");
        closeModal();
    };

    const handleExport = () => {
        http.get(`export-students?company_id=${user.company_id}`, {
            responseType: 'blob', // Important to handle the binary data
        })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'students.csv');
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
                setMessage('Students exported successfully');
            })
            .catch(error => {
                console.error('Export failed:', error);
                setMessage('Failed to export students');
            });
    };

    const handleSelectChangeNewOld = (e) => {
        const selectedValue = e.target.value;
        setSelectedNewOld(selectedValue);
    };

    return (
        <>
            <Toaster />
            <div className="mt-2 mb-2">
                {message && <p class="alert alert-success" role="alert">{message}</p>}
            </div>
            {showModal && (
                <form onSubmit={saveEditedCustemer}>
                    <div className="modal" tabindex="-1" style={{ display: "block" }}>
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Upload Profile Image</h5>
                                </div>

                                <div className="form-group p-2">
                                    <label>Admition No: {selStudent.adm_no} </label>
                                    <label><b></b></label>
                                </div>

                                <div className="form-group p-2">
                                    <label>Name: </label>
                                    <label><b>{selStudent.ext_2} {selStudent.ext_3}</b></label>
                                </div>

                                <div className="form-group p-2">
                                    <label>Class: {selStudent.class}</label>
                                    <label><b></b></label>
                                </div>

                                <div className="form-group  p-2">
                                    <label>Dev: </label>
                                    <label><b>{selStudent.section}</b></label>
                                </div>

                                <div className="form-group mt-3 p-3">
                                    <label>Photo</label>
                                    <input type="file" className="form-control" name='photo' onChange={handleFileChange} />
                                </div>

                                <div className="modal-footer">
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        onClick={closeModal}
                                    >
                                        Close
                                    </button>
                                    <button type="submit" className="btn btn-primary">
                                        Save changes
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            )}

            <div className="container mt-1">
                <div className="row">
                    <div className="col-8">

                        <div className="row">
                            <div className="col-4">
                                <h3 className="mb-1">
                                    Student List
                                </h3>
                            </div>
                        </div>
                    </div>

                    {(user.status === 1 && (user.department_id === 1 || user.department_id === 3)) && (
                        <div className="col-4 text-end mt-4">
                            <Link className="mb-3 add_link" to="/student_add" style={{ marginRight: '10px' }}>
                                Add Student
                            </Link>
                            <a onClick={handleExport} className="mb-3 button btn-primary btn">
                                Export
                            </a>

                        </div>
                    )}



                    <div className="row mb-2">
                        <div className="col-md-4 form-group">
                            <Select
                                options={options}
                                onChange={handleSelectChange}
                                value={selectedStud} // Set the selected value
                                isDisabled={isLoading} // Disable the select while loading
                                noOptionsMessage={noOptionsMessage} // Custom message
                            />
                        </div>


                        <div className="col-md-2 form-group text-end">
                            <select onChange={handleSelectChangeClass} className="form-control">
                                <option value="">Select Class</option>
                                {stClass.map((option, index) => (
                                    <option key={index} value={option.class_name}>
                                        {option.class_name}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="col-md-1 form-group text-end">
                            <select onChange={handleSelectChangeDev} className="form-control">
                                <option value="">Select Dev</option>
                                {stDev.map((options, index) => (
                                    <option key={index} value={options.name}>
                                        {options.name}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="col-md-2 form-group text-end">
                            <select onChange={handleSelectChangeAgent} className="form-control">
                                <option value="">Select Agent</option>
                                {stAgent.map((options, index) => (
                                    <option key={index} value={options.name}>
                                        {options.name}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="col-md-2 form-group text-end">
                            <select onChange={handleSelectChangeDevWay} className="form-control">
                                <option value="">Way</option>
                                <option value="One-Way">One Way</option>
                                <option value="Two-Way">Two Way</option>
                            </select>
                        </div>

                        <div className="col-md-1 form-group text-end">
                            <select onChange={handleSelectChangeNewOld} className="form-control">
                                <option value="">Type</option>
                                <option value="New">New</option>
                                <option value="Old">Old</option>
                            </select>
                        </div>

                        <div className="col-md-2 form-group ">
                            <Link to="" className="btn bt-sm btn-success" onClick={handleClearFields}>
                                Clear
                            </Link>
                        </div>

                    </div>

                </div>

                <div className="table-responsive">
                    {isLoading ? (
                        <TaskSkeleton />
                    ) : (
                        <table className="table table-striped center_tbl">
                            <thead className="table-primary">
                                <tr>
                                    <th className="text-danger">No</th>
                                    <th>Adm Date</th>
                                    <th>Adm No</th>
                                    <th>First Name</th>
                                    <th>Last Name</th>
                                    <th>Type</th>
                                    <th>Way</th>
                                    <th>Std</th>
                                    <th>Div</th>
                                    <th>Phone</th>
                                    <th>Agent</th>
                                    <th>Added By</th>

                                    <th width="27%" className="text-end">Action</th>

                                    {/* Add other fields as necessary */}
                                </tr>
                            </thead>
                            <tbody>
                                {tasks.map((taskuser, index) => (
                                    <tr
                                        className={editColumn === index ? "highlight" : ""}
                                        key={taskuser.id}
                                    >
                                        <td>{index + 1}</td>
                                        <td width="10%">
                                            {moment(taskuser.adm_date).format("DD MMM,YYYY")}</td>
                                        <td width="10%" className="">{taskuser.adm_no}</td>
                                        <td width="24%" className="text-uppercase">{taskuser.ext_2}</td>
                                        <td className="text-uppercase">{taskuser.ext_3}</td>
                                        <td>{taskuser.ext_10}</td>
                                        <td width="10%">{taskuser.ext_13}</td>
                                        <td>{taskuser.class}</td>
                                        <td>{taskuser.section}</td>
                                        <td>{taskuser.mobile}</td>
                                        <td>{taskuser.ext_11}</td>
                                        <td>{taskuser && taskuser.user_name ? (
                                            <td className="text-uppercase" key={taskuser.user_name.name}>{taskuser.user_name.name} </td>
                                        ) : null}</td>
                                        <td width="22%" className="text-end">
                                            {user.department_id === 1 && (
                                                <button
                                                    className="btn btn-link"
                                                    onClick={() => {
                                                        if (
                                                            window.confirm(
                                                                "Are you sure you want to delete this Student?"
                                                            )
                                                        ) {
                                                            deleteTask(taskuser.id);
                                                        }
                                                    }}
                                                >
                                                    D
                                                </button>
                                            )}

                                            <Link to={`/student_edit/${taskuser.id}`}
                                                className="btn  btn-sm btn-link"
                                            >
                                                ✏️
                                            </Link>

                                            <Link to={`/student_view/${taskuser.id}`} className="btn btn-sm btn-link action_link">
                                                View
                                            </Link>

                                            <button className='btn btn-sm btn-link action_link' onClick={() => openEditModal(taskuser.id, taskuser)}>Upload</button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>
            </div>
        </>
    );
}
